import "vant/es/loading/style";
import _Loading from "vant/es/loading";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "vant/es/nav-bar/style";
import _NavBar from "vant/es/nav-bar";
import "vant/es/col/style";
import _Col from "vant/es/col";
import "vant/es/row/style";
import _Row from "vant/es/row";
import "vant/es/cell-group/style";
import _CellGroup from "vant/es/cell-group";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "vant/es/image/style";
import _Image from "vant/es/image";
import "F:\\\u80F6\u5DF1\u4EBA\\jjr_store_reservation\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "F:\\\u80F6\u5DF1\u4EBA\\jjr_store_reservation\\node_modules\\core-js\\modules\\es.promise.js";
import "F:\\\u80F6\u5DF1\u4EBA\\jjr_store_reservation\\node_modules\\core-js\\modules\\es.object.assign.js";
import "F:\\\u80F6\u5DF1\u4EBA\\jjr_store_reservation\\node_modules\\core-js\\modules\\es.promise.finally.js";
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import '@/styles/common.less'; // 公共样式

import './assets/iconfont/iconfont.css'; // iconfont

import VueLazyLoad from 'vue-lazyload';
import axios from 'axios';
Vue.prototype.$http = axios;
window.HAS_TIPS = false; // 设置一个全局变量，用于处理扫码进首页重复判断“您已绑定**门店”

Vue.config.productionTip = false;
Vue.use(VueLazyLoad);
Vue.use(_Image);
Vue.use(_Cell);
Vue.use(_CellGroup);
Vue.use(_Row);
Vue.use(_Col);
Vue.use(_NavBar);
Vue.use(_Dialog);
Vue.use(_Loading);
new Vue({
  router: router,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');