import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var routers = [{
  path: '/refresh',
  name: 'refresh',
  component: function component() {
    return import('../views/refresh/refresh.vue');
  }
}];
export default routers;