//
//
//
//
//
//
//
//
export default {
  name: 'APP',
  data: function data() {
    return {};
  },
  computed: {
    keepAlive: function keepAlive() {
      return this.$route.meta.keepAlive;
    }
  },
  watch: {
    $route: function $route(to, from) {
      if (this.$route.path === '/login') {
        this.$route.meta.keepAlive = true;
      } else {
        this.$route.meta.keepAlive = false;
      }

      if (to.path === '/userAgreement' && from.path === '/login') {
        this.$route.meta.keepAlive = true;
      }

      if (to.path === '/userNotice' && from.path === '/login') {
        this.$route.meta.keepAlive = true;
      }
    }
  }
};