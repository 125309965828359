import _toConsumableArray from "F:\\\u80F6\u5DF1\u4EBA\\jjr_store_reservation\\node_modules\\@babel\\runtime\\helpers\\esm\\toConsumableArray.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.concat.js";
import Vue from 'vue';
import VueRouter from 'vue-router';
import refreshRouter from '../router/refresh';
Vue.use(VueRouter);
var routes = [// 首页
{
  path: '/',
  name: 'home',
  component: function component() {
    return import('../views/Home.vue');
  }
}, // 成功案例
{
  path: '/case',
  name: 'case',
  component: function component() {
    return import('../views/case.vue');
  }
}, // 订单
{
  path: '/package',
  name: 'package',
  component: function component() {
    return import('../views/package.vue');
  }
}, // 个人
{
  path: '/personal',
  name: 'personal',
  component: function component() {
    return import('../views/personal.vue');
  }
}, // 登录
{
  path: '/login',
  name: 'login',
  component: function component() {
    return import('../views/login.vue');
  }
}, // 首页-套餐详情
{
  path: '/homeDetails',
  name: 'homeDetails',
  component: function component() {
    return import('@/views/homeDetails.vue');
  }
}, // 下单确认信息
{
  path: '/confirmOrder',
  name: 'confirmOrder',
  component: function component() {
    return import('@/views/confirmOrder.vue');
  }
}, // 订单-套餐详情
{
  path: '/packageDetails',
  name: 'packageDetails',
  component: function component() {
    return import('@/views/packageDetails.vue');
  }
}, // 订单-疗程记录
{
  path: '/courseRecord',
  name: 'courseRecord',
  component: function component() {
    return import('@/views/courseRecord.vue');
  }
}, // 预约信息
{
  path: '/reservation',
  name: 'reservation',
  component: function component() {
    return import('@/views/reservation.vue');
  }
}, // 上传验光报告
{
  path: '/uploadReport',
  name: 'uploadReport',
  component: function component() {
    return import('@/views/uploadReport.vue');
  }
}, // 用户协议
{
  path: '/userAgreement',
  name: 'userAgreement',
  component: function component() {
    return import('@/views/userAgreement.vue');
  }
}, // 调理须知
{
  path: '/userNotice',
  name: 'userNotice',
  component: function component() {
    return import('@/views/userNotice.vue');
  }
}, // 测试
{
  path: '/test',
  name: 'test',
  component: function component() {
    return import('@/views/test.vue');
  }
}];
var router = new VueRouter({
  // routes
  routes: [].concat(_toConsumableArray(refreshRouter), routes)
});
export default router;